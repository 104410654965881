(function(){
  document.querySelectorAll('[data-expansion-controller]').forEach(el => {
    const activator = el.querySelector('[data-expansion-activator]');
    activator.addEventListener('click', (e) => {
      e.stopPropagation();
      el.classList.toggle('opened');
      if (activator.dataset.toggleContent){
        const content = activator.dataset.toggleContent;
        activator.dataset.toggleContent = activator.innerText;
        activator.innerText = content;
      }
    })
  });
})();
